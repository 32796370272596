// src/pages/PdfSelectPage.js

import React, {  useEffect } from 'react';
import PdfViewer from '../components/PdfViewer';
import PrintForm from '../components/PrintForm';
import logo from '../utils/logo.webp'; 
import { useNavigate } from 'react-router-dom';


function PdfSelectPage({ 
  selectedPdf,
  onSubmitPrintForm,
  uploadedFiles,
  setUploadedFiles,
  uploadedFilesInfo,
  setUploadedFilesInfo, 
  onAddAnotherFile, 
  numPages,
  file,
  handleFileUpload,
  isLoading,
  setIsLoading,
  isLoadingPages,
   }) {
  const navigate = useNavigate(); // Usar useNavigate aquí

  useEffect(() => {
    window.scrollTo(0, 0); // Mueve el scroll hacia la parte superior
  }, []); // [] asegura que se ejecute solo cuando el componente se monta

  // Función para simular una operación que desencadena el estado de carga
  const handleFormSubmit = async (form) => {
    setIsLoading(true);
    await onSubmitPrintForm(form); // Llamar a la función de envío
    setIsLoading(false);
  };
  // Esta función será llamada desde PdfViewer


  const handleNavigateHome = () => {
    localStorage.removeItem('uploadedFiles');
    setUploadedFiles([]); 
    localStorage.removeItem('uploadedFilesInfo');
    setUploadedFilesInfo([]); 
    navigate('/'); // Navega a la página de inicio
  };

  // Función para agregar otro archivo
  const handleAddAnotherFile = (file,form) => {
    
    setIsLoading(true);
    
    if (file) {
      onAddAnotherFile(file,form);
      window.scrollTo(0, 0);
      console.log("uploadedFiles updated in pdfSelect", [...uploadedFiles, file]);
    }
    
    setIsLoading(false);

  };
 
 

  return (
    <div className='pdfSelect'>
      <div className='pdfSelect-button'>
        <button className='button' onClick={handleNavigateHome}>Inicio</button>
      </div>
      <div className='pdfSelect-container'>
        <img className="logo" src={logo} alt="Logo" />
        {isLoading && (
          <div className="loading-overlay">
            <p>Cargando... Por favor, espera.</p>
          </div>
        )}

        {!isLoading && (
          <div className='pdfSelect-info'> 
            <div className="pdf-select-page">
              {isLoading ? (
              <div className="loading">
                <p>Cargando archivos...</p>
              </div>
            ) : (
              uploadedFiles.length > 0 && (
                <div className='archivos-subidos'>
                  <h4>Archivos subidos:</h4>
                  {uploadedFiles.map((file, index) => (
                    <div key={index}>
                      <p>{file.name}</p>
                    </div>
                  ))}
                </div>
              )
              
            )}
            <PdfViewer selectedPdf={selectedPdf}
              file={file}
              numPages={numPages}  
              isLoading={isLoading}
              isLoadingPages={isLoadingPages}
              />  
            </div>
            <PrintForm onSubmit={handleFormSubmit}
              onAddAnotherFile={handleAddAnotherFile}
              numPages={numPages}
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles} 
              setUploadedFilesInfo={setUploadedFilesInfo} 
              handleFileUpload={handleFileUpload}
              />
          </div>
        )}

      </div>
     
    </div>
    
  );
}

export default PdfSelectPage;
