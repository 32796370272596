export const bancolombiaConfig = {
  data: {"data": {
    "bankId": "BANCOLOMBIA",
    "type": "COMPRAS_Y_RECAUDOS",
    "nature": "DINAMICO",
    "creationInformation": {
      "documentType": "CC",
      "documentNumber": "71264987",
      "user": ""
    },
    "customers": {
      "documentType": "CC",
      "documentNumber": "1152450587",
      "name": ""
    },
    "accounts": {
      "type": "CUENTA_DE_AHORRO",
      "number": "95929871317"
    },
    "commerce": {
      "name": "PrintExpress",
      "category": "EDUCACION",
      "transactionUrl": "",
      "salesPoint": {
        "id": "PIEX1",
        "name": "PrintExpress #1"
      },
      "checkout": {
        "id": "",
        "name": ""
      },
      "seller": {
        "id": "",
        "name": ""
      }
    },
    "transactions": {
      "businessReference": "Impresión Mega Papel",
      "amount": 1,
      "transactionReference": "Impresion PrintExpress"
    },
    "aditionalNotificacion": [
      {
        "contactPhone": "3128368138",
        "email": "mariao.1994@gmail.com"
      }
    ]
  }},
  headers: {
    "Content-Type": "application/vnd.bancolombia.v4+json",
    "Accept":"application/vnd.bancolombia.v4+json",
    "Client-secret":"7fc95cd24e0b5c90c505c7f97e70951e",
    "Client-id":"bfb29938b664035bf43784891645b46b"
  },
};