import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../utils/logo.webp'; 
import { useNavigate } from 'react-router-dom';

const PricesPage = () => {
  // Estado para almacenar los filtros seleccionados
  const [material, setMaterial] = useState('');
  const [size, setSize] = useState('');
  const [color, setColor] = useState('');
  const [sides, setSides] = useState('');
  const [availableSizes, setAvailableSizes] = useState([]);
  const [total, setTotal] = useState(null);
  const navigate = useNavigate();
  const [nHojas, setNHojas] = useState('1');

  // Función para manejar el cambio de filtros
  const handleFilterChange = (e, setFilter) => {
    setFilter(e.target.value);
  };
  useEffect(() => {
    switch (material) {
      case 'Propalcote 200g':
      case 'Propalcote 300g':
        setAvailableSizes(['Carta', 'Oficio', 'Tabloide 32x45']);
        if (size === 'Gran formato 70x100') setSize(''); 
        break;
      case 'Opalina':
        setAvailableSizes(['Carta']);
        if (size === 'Tabloide 32x45') setSize(''); 
        break;
      case 'Fotográfico':
          setAvailableSizes(['A4']);
          setSides(['Una cara'])
          if (size ===! 'A4') setSize(''); 
          if (sides ===! 'Una cara') setSize(''); 
          break;
      case 'Cartulina':
        setAvailableSizes(['Carta','Tabloide 32x45']);
        if (size ===! 'A4') setSize(''); 
        break;
      default:
        setAvailableSizes(['Carta', 'Oficio', 'Tabloide 32x45', 'Gran formato 70x100']);
        break;
    }
  }, [material, size, sides]);

  useEffect(() => {
    const fetchTotal = async () => {
        try {
            const response = await axios.get('https://databasemegapapel-production.up.railway.app/api/prices-cot', {
                params: {
                    material,
                    size,
                    color,
                    sides
                }
            });

            console.log(response.data); // Verifica la respuesta en la consola
            
            // Verifica si 'total' está disponible en la respuesta
            if (response.data.total!== undefined && material && size && color && sides ) {
                setTotal(response.data.total * nHojas); // Multiplica por 'nHojas'
            } else {
                setTotal(null); // Manejo en caso de error o falta de datos
            }
        } catch (error) {
            console.error('Error al obtener total de precios', error);
            navigate('/error');
        }
    };

    // Llamar a la función para obtener el total
    fetchTotal();
}, [material, size, color, sides, nHojas,navigate]); 
  const handleNavigateHome = () => {
    navigate('/'); // Navega a la página de inicio
  };
  return (
    <div className='prices-page'>
      <button className='button'  onClick={handleNavigateHome}>Inicio</button>
      <div className='prices-list'>
        <img className="logo" src={logo} alt="Logo" /> 
        <div className='prices-info'>
          <div className='prices-list-info'>
            <h1>Lista de Precios:</h1>
            <label>
                Blanco y negro | Color:
                <div className="select-container">
                  <select value={color} onChange={(e) => handleFilterChange(e, setColor)}  required>
                    <option value="">Seleccionar opción</option>
                    <option value="Blanco y negro">Blanco y negro</option>
                    <option value="Color">Color</option>
                  </select>
                </div>
              </label>
            <label>
              Tipo de papel:
              <div className="select-container">
                {/* <select value={material} onChange={(e) => setMaterial(e.target.value)} required> */}
                <select value={material} onChange={(e) => handleFilterChange(e, setMaterial)} required>
                  <option value="">Seleccionar material</option>
                  <option value="Papel normal">Papel normal</option>
                  <option value="Cartulina">Cartulina</option>
                  <option value="Propalcote 200g">Propalcote 200g</option>
                  <option value="Propalcote 300g">Propalcote 300g</option>
                  <option value="Opalina">Opalina</option>
                  <option value="Fotográfico">Fotográfico</option>
                </select>
              </div>
            </label>
            <label>
              Tamaño:
              <div className="select-container">
                <select value={size} onChange={(e) => handleFilterChange(e, setSize)}  required>
                  <option value="">Seleccionar tamaño</option>
                  {availableSizes.map((sizeOption) => (
                    <option key={sizeOption} value={sizeOption}>
                      {sizeOption}
                    </option>
                  ))}
                </select>
              </div>
            </label>
            <label>
              Lados:
              <div className="select-container">
              <select value={sides} onChange={(e) => handleFilterChange(e, setSides)} required>
                <option value="">Selecciona Lados</option>
                <option value="Una cara">Una cara</option>
                <option value="Doble cara">Doble cara</option>
              </select>
              </div>
            </label>
            <label>
              # Hojas:
              <input
                type="number"
                value={nHojas}
                min={1}
                onChange={(e) => setNHojas(Number(e.target.value))}
                onFocus={() => setNHojas('')} 
                required
              />
            </label>
          </div>
        {/* Lista de Precios */}
        <div className='prices-list-total'>
          {/* Mostrar total si todos los filtros están seleccionados */}
          {total !== null ? (
            <div>
              <h2>Total: ${total}</h2>
            </div>
          ) : (
            <p>Por favor selecciona todos los filtros para ver el total.</p>
          )}
        </div>
        </div>
      </div>
    
      
    </div>
  );
};

export default PricesPage;
