import React from 'react';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;



function PdfViewer({ selectedPdf,
   file,
   numPages,
   isLoading,
  isLoadingPages,
  
}) {

  
  return (
    <div className="email-view-page">
     {(isLoading || isLoadingPages) ? (
      <div className="loading-overlay">
        <p>Cargando... Por favor espera</p>
      </div>
    ) : (
      <div className="scroll-container">
        {selectedPdf && (
          // Verificar si el archivo es un PDF
          file.type === 'application/pdf' ? (
           
            <embed src={selectedPdf} type="application/pdf" />
            
          ) : (
            <img src={selectedPdf} alt="Uploaded" />
          )
        )}
      </div>
    )}

    {!isLoading && !isLoadingPages && numPages && <p>Total de páginas: {numPages}</p>}
    </div>
  );
}

export default PdfViewer;