import React, { useEffect } from 'react';
import logo from '../utils/logo.webp'; 
import { useNavigate } from 'react-router-dom';

function LocalPaymentInfo({setUploadedFiles}) {
  const navigate = useNavigate(); // Usar useNavigate aquí

  const handleNavigateHome = () => {
    localStorage.removeItem('uploadedFiles');
    setUploadedFiles([]); 
    navigate('/'); // Navega a la página de inicio
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Mueve el scroll hacia la parte superior
  }, []); // [] asegura que se ejecute solo cuando el componente se monta

  useEffect(() => {

    // Timeout para detener el intervalo después de 2 minutos (120,000 milisegundos)
    const timeout = setTimeout(() => { 
      localStorage.removeItem('uploadedFiles');
      setUploadedFiles([]); 
      navigate('/'); // Redirigir a la página principal
    }, 10000);

    // Limpiar intervalos y timeouts cuando el componente se desmonte
    return () => {
      clearTimeout(timeout); // Limpiar el timeout
    };

  }, [navigate,setUploadedFiles]);


  return (
    <div className='local-payment'>
      
      
        <img className="logo" src={logo} alt="Logo" />
        <div className='local-payment-info'>
          <h2>Visítanos en nuestra tienda para realizar el pago</h2>
          <h2>y procederemos con la impresión.</h2>
          <button className='button'  onClick={handleNavigateHome}>Inicio</button>
        </div>
      
    </div>
    
  );
}

export default LocalPaymentInfo;