import React, { useState } from 'react';
import FileUpload from '../components/FileUpload';
import { useNavigate } from 'react-router-dom';
import logo from '../utils/logo.webp'; 
import { Link } from 'react-router-dom';



function HomePage({ onFileUpload }) {
  const [error, setError] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const navigate = useNavigate(); // Usar useNavigate aquí


  const handleFileUpload = (file) => {
    if (file) {
      console.log("Se ha subido el archivo exitosamente:",file.name)
      onFileUpload(file);  // Llamar a la función de App.js para actualizar los archivos
      setError(null);
      setUploadedFiles((prevFiles) => [...prevFiles, file.name]);
      navigate('/pdf-select');  // Navegar a la página de selección de PDF
    } 
  
  };

  const handleError = (errorMessage) => {
    console.log("Ha habido un error al seleccionar el archivo")
    setError(errorMessage);
  };
 
  return (
    <div className="home-page">
      {/* <EmailInput email={email} setEmail={setEmail} onEmailSubmit={handleEmailSubmit} /> */} 
      
      <div className='home'>
        <img className="logo" src={logo} alt="Logo" /> 
        <div className='info'>
          <div className='texto'>
            <h1>Imprime con Nosotros Fácil y Rápido</h1>
            <h3>Sube tus archivos, paga en línea y recoge en el punto físico. ¡Así de simple!</h3>
          </div>
          <div className='buttonSelect'>
            <h2>Archivos permitidos:</h2>
            <h3>PDF, PNG JPEG y JPG</h3>
              {error && <p className="error-message">{error}</p>}
              <FileUpload onFileUpload={handleFileUpload} onError={handleError} />
          </div>
            <Link className="prices-button" to="/prices">
              Cotizador
            </Link>
            <p>Apartadó, Antioquia</p>
          
      
      </div>
        
        
    </div>
      

    </div>
  );
}

export default HomePage;
